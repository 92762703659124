const styles = {
  wrapper: {
    display: "flex",
    "flex-wrap": "wrap",
    justifyContent: "center",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
    "&.story": {
      marginTop: "0",
    },
    "&.store": {
      marginTop: ["20px", "30px", "70px"],
    },
    ".links": {
      textDecoration: "none",
      color: "#054752",
      background: "none",
      width: 27,
      height: 27,
      display: "block",
      "text-align": "center",
      lineHeight: "27px",
    },
    ".current": {
      color: "#fff",
      background: "#054752",
      borderRadius: "50%",
      width: 27,
      height: 27,
    },
    ".arrow": {
      border: "solid #054752",
      borderWidth: "0 3px 3px 0",
      display: "inline-block",
      padding: "3px",
      height: 6,
      width: 6,
    },
    ".next": {
      transform: "rotate(-45deg)",
      "WebkitTransform": "rotate(-45deg)",
    },
    ".prev": {
      transform: "rotate(135deg)",
      "WebkitTransform": "rotate(135deg)",
    },
  },
};

export default styles;
