const styles = {
  container: {
    padding: "0 80px",
  },
  banner: {
    position: "relative" as "relative",
    display: "inline-block",
    width: "100%",
    height: "100%",
    marginTop: ["30px", "50px", 0],
  },
  imageWrap: {
    width: "100%",
    height: ["270px", "300px", "360px"],
    img: {
      height: "100%",
    },
  },
  bannerWrap: {
    maxWidth: "1250px",
    width: ["100%", "90%"],
    position: "absolute" as "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },
  bannerInfo: {
    width: "100%",
    "@media screen and (min-width:32em)": {
      width: "100%",
      marginTop: "30px",
    },
    "@media screen and (min-width:48em)": {
      width: "80%",
    },
    "@media screen and (min-width:64em)": {
      width: "60%",
    },
    "@media screen and (min-width:86em)": {
      width: "600px",
    },
    color: "#fff",
    opacity: "0.9",
    padding: "25px",
    p: {
      margin: "10px",
      fontSize: ["14px", "16px", "18px"],
      width: ["100%", "80%", "70%"],
    },
  },
  bannerHeading: {
    position: "relative" as "relative",
    fontSize: ["35px", "50px", "60px"],
    letterSpacing: "1px",
    lineHeight: "1.1em",
    fontWeight: "600",
    marginTop: "0",
    marginBottom: ".17em",
    zIndex: "10",
    "text-align": ["center", "left"],
  },
  bannerSubHeading: {
    fontSize: ["17px", "18px", "20px"],
    fontWeight: 500,
    lineHeight: ["1.4em", "1.6em"],
    fontFamily: "Montserrat",
    margin: "0 0 6px",
    padding: "5px 0",
    zIndex: "10",
    "text-align": ["center", "left"],
  },
  description: {
    fontSize: ["17px", "19px"],
    padding: "20px 0",
    "text-align": "center",
    p: {
      m: 0,
      lineHeight: "1.5em",
    },
    "@media only screen and (min-width: 560px)": {
      padding: "20px 25px 15px",
    },
    "@media only screen and (min-width: 1200px)": {
      padding: "25px 50px 20px",
    },
  },
};

export default styles;
