import React, { FC } from "react";
import PrimaryLayout from "../components/layout/primary/primary";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import { Text, Box } from "theme-ui";
import Img from "gatsby-image";
import BlogPostPreview from "../components/blog/blog-post-preview";
import Pagination from "../components/pagination/pagination";
import FlexWrapper from "../components/container/flex-wrapper";
import ContentContainer from "../components/container/content-container";
import ColorDivider from "../components/color-divider/color-divider";
import PhotoSlider from "../components/photo-slider/photo-slider";
import { RichText } from "prismic-reactjs";
import styles from "../components/story-archive.style";

export const query = graphql`
  query StoryArchivePage($stories: ID!, $limit: Int!, $skip: Int!) {
    photoseries: allPrismicBlogContentModel(
      sort: { fields: first_publication_date, order: DESC }
      limit: 1
      filter: {
        data: { story_content_type: { id: { eq: "YBqpzREAACAAQSMp" } } }
      }
    ) {
      edges {
        node {
          uid
          data {
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
            body {
              ... on PrismicBlogContentModelDataBodyImageGallery {
                items {
                  gallery_image {
                    fluid(maxWidth: 475) {
                      ...GatsbyImgixFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    storyType: prismicStoryType(prismicId: { eq: $stories }) {
      data {
        name
        text_overlay
        heading
        subheading
        description {
          raw
        }
        banner_image {
          fluid(maxWidth: 1575) {
            ...GatsbyImgixFluid_noBase64
          }
        }
      }
    }
    main: allPrismicBlogContentModel(
      sort: { fields: data___release_date, order: DESC }
      limit: $limit
      skip: $skip
      filter: { data: { story_content_type: { id: { eq: $stories } } } }
    ) {
      edges {
        node {
          data {
            release_date
            post_title {
              text
            }
            excerpt
            story_content_type {
              document {
                ... on PrismicStoryType {
                  data {
                    name
                  }
                }
              }
            }
            featured_image {
              fluid(maxWidth: 575) {
                ...GatsbyImgixFluid_noBase64
              }
            }
          }
          uid
        }
      }
    }
  }
`;

interface StoryArchiveProps {
  data: any;
  pageContext: any;
}

const StoryArchive: FC<StoryArchiveProps> = ({ data, pageContext }) => {
  const postData = data.main.edges;
  const { name, slug, numStoryPages, currentPage } = pageContext;
  const storiesLg = data.photoseries.edges[0].node;
  const storyType = data.storyType.data;
  const photoArray = storiesLg.data.body[0].items;
  //const category = storiesLg.data.story_content_type?.document.data.name
  const description = storyType?.description.raw;
  const textOverlay = storyType.text_overlay;
  const bannerImage = storyType?.banner_image?.fluid;
  const featuredImage = storiesLg.data.featured_image;
  const heading = storyType?.heading;
  const subheading = storyType?.subheading;
  const currentSlug = `/${slug}`;

  return (
    <>
      <PrimaryLayout shop>
        <SEO title={name} />
        {bannerImage && (
          <Box sx={styles.banner}>
            <Box sx={styles.imageWrap}>
              <Img fluid={bannerImage} style={{ height: "100%" }} />
            </Box>
            <Box sx={styles.bannerWrap}>
              <Box sx={styles.bannerInfo}>
                {textOverlay && (
                  <>
                    <Text as="h2" sx={styles.bannerSubHeading}>
                      {subheading}
                    </Text>
                    <Text as="h1" sx={styles.bannerHeading}>
                      {heading}
                    </Text>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
        <ContentContainer width="100%">
          {description.length && description[0]?.text !== "" ? (
            <Box sx={styles.description}>{RichText.render(description)}</Box>
          ) : null}
          {name === "Impressions" && (
            <>
              <PhotoSlider
                images={photoArray}
                link={storiesLg?.uid}
                //category={category}
                featured={featuredImage}
              />
              <Box sx={styles.container}>
                <ColorDivider color="cgFushia" />
              </Box>
            </>
          )}
          {name !== "Impressions" && !bannerImage && (
            <FlexWrapper padded>
              <Text
                as="p"
                sx={{
                  fontSize: "18px",
                  color: "#D79A2B",
                  paddingBottom: 0,
                  "@media only screen and (min-width: 560px)": {
                    fontSize: "23px",
                  },
                }}
              >
                {name}
              </Text>
            </FlexWrapper>
          )}
          <Box sx={styles.container}>
            <FlexWrapper padded>
              {postData.map((post: any) => {
                return (
                  <BlogPostPreview
                    key={post.node.uid}
                    content={post.node}
                    previewStyle="stories"
                    archive
                    hideCat
                  />
                );
              })}
            </FlexWrapper>
            {numStoryPages > 1 && (
              <Pagination
                numPages={numStoryPages}
                currentPage={currentPage}
                currentSlug={currentSlug}
                story
              />
            )}
            <Box
              sx={{
                paddingBottom: "30px",
                "@media screen and (min-width: 591px)": {
                  paddingBottom: "50px",
                },
              }}
            />
            {/*<Box sx={{
            "@media screen and (max-width: 590px)": {
              display: "none",
            }}}>
            <ColorDivider color="cgMustard" />
          </Box>
          */}
            <div>{/*<Instagram />*/}</div>
          </Box>
        </ContentContainer>
      </PrimaryLayout>
    </>
  );
};
export default StoryArchive;
