import { Link } from "gatsby";
import { Box } from "theme-ui";
import styles from "./pagination.style";

type PropsType = {
  numPages?: any;
  currentPage?: any;
  currentSlug?: string;
  isProductPage?: boolean;
  story?: boolean;
};

const Pagination: React.FC<PropsType> = ({
  numPages,
  currentPage,
  currentSlug,
  isProductPage,
  story
}) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? currentSlug
      : `${currentSlug}/${(currentPage - 1).toString()}`;
  const nextPage = `${currentSlug}/${(currentPage + 1).toString()}`;
  const addAnchor = isProductPage ? '#main' : ''

  return (
    <div>
      <Box as="ul" sx={styles.wrapper} className={story ? "story" : "store"}>
        {!isFirst && (
          <Link to={`${prevPage}${addAnchor}`} rel="prev" className="links arrow prev"></Link>
        )}
        {Array.from({ length: numPages }, (_, i) => (
          <li
            key={`pagination-number${i + 1}`}
            style={{
              margin: 0,
              marginLeft: 0.5,
            }}
          >
            <Link
              to={i === 0 ? `${currentSlug}${addAnchor}` : `${currentSlug}/${i + 1}${addAnchor}`}
              className={i + 1 === currentPage ? "links current" : "links"}
            >
              {i + 1}
            </Link>
          </li>
        ))}
        {!isLast && (
          <Link to={`${nextPage}${addAnchor}`} rel="next" className="links arrow next"></Link>
        )}
      </Box>
    </div>
  );
};

export default Pagination;
